import React from 'react';
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga';

//import { gaId } from '../../utils';

export default function Erro(props) {     

    const isOpen = useSelector(state => state.open);    
    /*
    useEffect(() => {
        ReactGA.initialize(gaId, {debug: false});
        ReactGA.pageview(props.location.pathname);
    },[])
    */

    return (
        <>
            <div className={`${isOpen ? 'open ': ''}main border-top`}> 

                <div className="container py-5 my-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-8">
                            <div className="font-80 color-secondary opacity-25 line-height-100"><b>404</b></div>
                            <div className="font-28 color-secondary opacity-50"><b>Esta Página não está disponível.</b></div>
                            <div className="font-16 w-50 m-auto">O link que você seguiu pode estar quebrado ou a página pode ter sido removida.</div>
                        </div>
                    </div>                    
                </div>

            </div>
        </>
    );   
    
}
