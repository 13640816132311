import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import QuemSomos from './pages/QuemSomos';
import Aluguel from './pages/Aluguel';
import Venda from './pages/Venda';
import BancoDePedidos from './pages/BancoDePedidos';
import FaleConosco from './pages/FaleConosco';
import Imovel from './pages/Imovel';
import Noticia from './pages/Noticia';
import Busca from './pages/Busca';
import Erro from './pages/Erro';

export default function Routes() {
    return (
        <>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/quem-somos" component={QuemSomos} />
                <Route path="/aluguel" component={Aluguel} />
                <Route path="/venda" component={Venda} />
                <Route path="/banco-de-pedidos" component={BancoDePedidos} />
                <Route path="/fale-conosco" component={FaleConosco} />
                <Route path="/imovel/:id" component={Imovel} />
                <Route path="/noticia/:id" component={Noticia} />
                <Route path="/busca" component={Busca} />
                <Route component={Erro} />            
            </Switch>
        </>
    );
}