import React, {  useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
//import ReactGA from 'react-ga';
import ContentHeade from '../../components/ContentHeader';
// import Select from 'react-select';
// import NumberFormat from 'react-number-format';
import { ToastContainer } from 'react-toastify';

import { descriptionDefault, urlSite, titleSite, urlFavicon } from '../../utils';

import FotoQuemSomos from '../../assets/img/foto-destaque-banco-de-pedidos.jpg';

export default function QuemSomos(props) { 
    
    const isOpen = useSelector(state => state.open);
    
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },[])

    return (
        <>
            
            <Helmet>
                
                <link rel="apple-touch-icon" sizes="57x57" href={ `${urlFavicon}apple-icon-57x57.png`} />
                <link rel="apple-touch-icon" sizes="60x60" href={ `${urlFavicon}apple-icon-60x60.png` } />
                <link rel="apple-touch-icon" sizes="72x72" href={ `${urlFavicon}apple-icon-72x72.png` } />
                <link rel="apple-touch-icon" sizes="76x76" href={ `${urlFavicon}apple-icon-76x76.png` } />
                <link rel="apple-touch-icon" sizes="114x114" href={ `${urlFavicon}apple-icon-114x114.png` } />
                <link rel="apple-touch-icon" sizes="120x120" href={ `${urlFavicon}apple-icon-120x120.png` } />
                <link rel="apple-touch-icon" sizes="144x144" href={ `${urlFavicon}apple-icon-144x144.png` } />
                <link rel="apple-touch-icon" sizes="152x152" href={ `${urlFavicon}apple-icon-152x152.png` } />
                <link rel="apple-touch-icon" sizes="180x180" href={ `${urlFavicon}apple-icon-180x180.png` } />
                <link rel="icon" type="image/png" sizes="192x192"  href={ `${urlFavicon}android-icon-192x192.png` } />
                <link rel="icon" type="image/png" sizes="32x32" href={ `${urlFavicon}favicon-32x32.png` } />
                <link rel="icon" type="image/png" sizes="96x96" href={ `${urlFavicon}favicon-96x96.png` } />
                <link rel="icon" type="image/png" sizes="16x16" href={ `${urlFavicon}favicon-16x16.png` } />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content={ `${urlFavicon}ms-icon-144x144.png` } />
                <meta name="theme-color" content="#ffffff" />
                <meta name="description" content={descriptionDefault} />
                <meta name="og:site_name" property="og:site_name" content={titleSite} />
                <meta name="og:title" property="og:title" content={`Clik imóveis | ${titleSite}`} />
                <meta name="og:url" property="og:url" content={`${urlSite}/quem-somos`} />
                <meta name="og:description" property="og:description" content={descriptionDefault} />                             
                <meta name="og:image" property="og:image" content={`${urlFavicon}padrao.png`} />
                <meta name="og:image:width" property="og:image:width" content="300" />
                <meta name="og:image:height" property="og:image:height" content="300" />
                <title>Clik imóveis | { titleSite }</title>
                
            </Helmet>
            
            <div className={`${isOpen ? 'open ': ''}main`}>

                <ContentHeade title="Clik imóveis" routes={props} />

                <div className="container py-5 font-14 px-4 px-sm-0">

                    <div className="row py-4">

                        <div className="d-none d-xl-block col-xl-4">
                            <img src={FotoQuemSomos} alt="" />
                        </div>

                        <div className="col-12 col-xl-8">

                            <h4 class="mb-4">.</h4>
                            

                        </div>

                    </div>

                </div>

            </div>

            <ToastContainer />

        </>
    );
}